<template>
  <div id="divBusinessPartnersWrapper">
    <VueContentHolder class="content-wrapper" :padding="[20, 20, 0]">
      <div class="landing">
        <BrandLogo :width="60" :height="60" :image="partner.image" />
        <div class="right-area">
          <VueHeadline level="3" weightLevel="6" color="grey-40" class="headline-text"
            >Türkiye’de Philip Morris</VueHeadline
          >
          <VueText sizeLevel="3" color="grey-30" class="text">
            <div v-html="headContent"></div>
          </VueText>
        </div>
      </div>
      <VueContentHolder class="people-wrapper">
        <BrandMeetPerson :person="people.te" :cornerIcon="icons.phoneAlt" />
      </VueContentHolder>
      <div class="tabs">
        <div>
          <BrandButton
            @click="setActiveTab('visitDays')"
            shape="rounded"
            :radius="50"
            :size="sizes.xSmall"
            class="tab-btn"
            :outlined="activeTab !== 'visitDays'"
            ><VueText weightLevel="4" sizeLevel="3">ZİYARET GÜNLERİM</VueText></BrandButton
          >
        </div>
        <div>
          <BrandButton
            @click="setActiveTab('orderDays')"
            :size="sizes.xSmall"
            shape="rounded"
            :radius="50"
            :outlined="activeTab !== 'orderDays'"
            class="tab-btn"
            ><VueText weightLevel="4" sizeLevel="3">ÖN SİPARİŞ GÜNLERİM</VueText></BrandButton
          >
        </div>
      </div>
      <div class="days-table" v-for="(partner, i) in partners" :key="i">
        <div class="day" v-for="(day, index) in days" :key="index">
          <div class="day-name">{{ getDayName(day) }}</div>
          <div class="day-check">
            <VueIcon
              v-if="isChecked(day, partner)"
              :iconName="icons.iconChecked.name"
              :width="icons.iconChecked.width"
              :height="icons.iconChecked.height"
            />
          </div>
        </div>
      </div>

      <BrandProductPriceToolbar partner="PMI" :routes="routes" :active-route="routes.priceList" />

      <div class="items-wrapper">
        <VueListItem
          as="router-link"
          :text="item.text"
          :icon="item.icon"
          borderPosition="top"
          :contentAlignment="flexAlignment.between"
          v-for="item in items"
          :to="item.path"
          :key="item.id"
        ></VueListItem>
      </div>
    </VueContentHolder>

    <VueContentHolder class="content-wrapper" :padding="[20]">
      <VueHeadline class="products-headline" level="3" weightLevel="3">Markalar</VueHeadline>
      <div class="products-wrapper">
        <BrandProduct
          v-for="product in products"
          :key="product.pmCode"
          :productName="product.name"
          :productPath="`pmi/brand/${product.brandId}`"
          :productImageSrc="product.image"
        ></BrandProduct>
      </div>
    </VueContentHolder>
  </div>
</template>
<script>
import dateUtils from '@/mixins/dateUtils';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import BrandProductPriceToolbar from '@/components/brand/BrandProductPriceToolbar/BrandProductPriceToolbar.vue';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { Product } from '@/services/Api/index.js';
import RoutesBusinessPartners from '@/router/routes/secure/RoutesBusinessPartners';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import StaticContent from '@/services/Api/staticContents';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { User } from '@/services/Api/index';
import { BUSINESS_PARTNERS } from '@/constants/businessPartners.constants';
import { DAYS } from '@/constants/days.constants';

export default {
  name: 'BusinessPartnersHome',
  components: {
    VueListItem,
    VueHeadline,
    VueText,
    VueContentHolder,
    BrandMeetPerson,
    BrandProduct,
    BrandProductPriceToolbar,
    BrandLogo,
    VueIcon,
    BrandButton,
  },
  mixins: [dateUtils],
  beforeCreate() {
    Product.getProducts().then(res => {
      const {
        Data: { brands, teUser, teUserPhone, partner },
      } = res.data;
      this.partner = partner;
      this.products = brands;
      this.people.te.name = teUser ? teUser.trim().toLocaleUpperCase('tr-TR') : '-';
      this.people.te.phone = teUserPhone;
    });
  },
  created() {
    this.getRouteDays();
    this.getFaturaButtonStatus();
  },
  computed: {
    items() {
      let res = [
        {
          text: 'Faturalarım',
          icon: ICON_VARIABLES['sheetTable'],
          path: `${RoutesBusinessPartners.Pmi.path}/${RoutesPmi.Bills.path}`,
        },
        {
          text: 'Tek Tip Paket',
          icon: ICON_VARIABLES['packages'],
          path: `${RoutesBusinessPartners.Pmi.path}/${RoutesPmi.PlainPackage.path}`,
        },
      ];
      if (!this.faturaButton) res.shift();
      return res;
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    flexAlignment() {
      return FLEX_JUSTIFIES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    routes() {
      return {
        priceList: `${RoutesBusinessPartners.Pmi.path}/${RoutesPmi.PriceList.path}`,
        productList: `${RoutesBusinessPartners.Pmi.path}/${RoutesPmi.ProductList.path}`,
      };
    },
  },
  data() {
    return {
      headContent: null,
      faturaButton: null,
      partner: {},
      products: [],
      teUser: '',
      people: {
        te: { title: 'PMI İş Geliştirme Sorumlun', name: '', phone: '' },
      },
      activeTab: 'visitDays',
      days: DAYS,
      partners: [],
    };
  },
  beforeMount() {
    StaticContent.getStaticContent('BusinessPartnerPMI').then(res => {
      if (res.data && res.data.Data && res.data.Data.content) {
        this.headContent = res.data.Data.content;
      }
    });
  },
  methods: {
    getRouteDays() {
      User.getRouteDays()
        .then(res => {
          if (res.data && res.data.Data) {
            res.data.Data.forEach(f => {
              if (f.partnerName == BUSINESS_PARTNERS.PMI.partnerName) this.partners = [f];
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setActiveTab(tab) {
      if (this.activeTab != tab) {
        this.activeTab = tab;
      }
    },
    isChecked(day, partner) {
      if (this.activeTab === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    },
    async getFaturaButtonStatus() {
      await StaticContent.getStaticContent('FaturalarButon').then(res => {
        this.faturaButton = !!+res.data.Data.content;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.content-wrapper {
  background-color: #f8f9f9;
  height: unset;
}
.landing {
  display: flex;
  padding-bottom: palette-space-level(15);
  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      line-height: 1.27;
      padding-right: palette-space-level(10);
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}

.actions {
  display: flex;
  padding: palette-space-level(20) 0;
  z-index: 5;

  button:not(:first-child) {
    margin-left: palette-space-level(10);
  }
  button:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}

.items-wrapper {
  margin-top: palette-space-level(10);
}
.people-wrapper {
  padding: palette-space-level(20) 0;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: palette-space-level('20');
}
.products-headline {
  padding: palette-space-level('10') 0;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: palette-space-level(25);
  margin-left: palette-space-level(50);
  z-index: 5;
  & > div {
    &:first-child {
      margin-right: palette-space-level(15);
      @media screen and (max-width: 325px) {
        margin-right: palette-space-level(10);
      }
    }
  }
  .tab-btn {
    padding: 5px 10px;
  }
}

.days-table {
  margin-top: palette-space-level(20);
  margin-left: palette-space-level(20);
  margin-right: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > div {
    &:first-child {
      border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    }
  }
}
.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);

  &-name {
    background-color: palette-color-level('grey', 10);
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.info-text {
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
}
.weekly-wrapper {
  margin-bottom: palette-space-level(50);
}
</style>
